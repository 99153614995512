import React, { useEffect } from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import PrimaryButton from "./PrimaryButton";

import Container from "../styles/Container";

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  .slide {
    background: white !important;
  }
  .dot {
    opacity: 1 !important;
    width: 11px !important;
    height: 11px !important;
    background-color: ${(p) => p.theme.lightMidGrey} !important;
    box-shadow: none !important;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      width: 13px !important;
      height: 13px !important;
    }
    :hover {
      background-color: ${(p) => p.theme.midGrey} !important;
    }
  }
  .dot.selected {
    background-color: ${(p) => p.theme.midGrey} !important;
  }
`;

const SlideContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  //min-height: 300px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-height: 410px;
  }
  @media (min-width: 1400px) {
    max-height: 60vh;
  }
`;

const HeadingContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 24px;
  padding-bottom: 46px;
  background-color: ${(p) => p.theme.greyishBlue};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 32px;
    padding-bottom: 54px;
  }
`;

const Heading = styled.h1`
  font-size: 24px;
  line-height: 30px;
  font-weight: 900;
  margin-bottom: 16px;
  text-align: center;
  color: white;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 44px;
    line-height: 52px;
  }
`;

const SubHeading = styled.h2`
  font-family: "effra", sans-serif;
  font-size: 21px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 30px;
    line-height: 40px;
  }
`;

const HomepageHero = ({ items }) => {
  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 100);
  });
  return (
    <Container fullwidth>
      {items && items[0].image && (
        <CarouselContainer>
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={5000}
            transitionTime={500}
            swipeScrollTolerance={50}
          >
            {items.map(
              (
                { image, heading, subheading, button_link, button_text },
                index
              ) => {
                return (
                  <SlideContainer key={index}>
                    <Image src={image.url} alt={image.alt} />
                    <HeadingContainer>
                      <Container>
                        {heading && <Heading>{heading}</Heading>}
                        {subheading && <SubHeading>{subheading}</SubHeading>}
                        {button_text && button_link && (
                          <PrimaryButton
                            small
                            externalLink={button_link.url}
                            text={button_text}
                            target={
                              button_link.target ? button_link.target : "_self"
                            }
                          />
                        )}
                      </Container>
                    </HeadingContainer>
                  </SlideContainer>
                );
              }
            )}
          </Carousel>
        </CarouselContainer>
      )}
    </Container>
  );
};

export default HomepageHero;
