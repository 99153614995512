import React from "react";
import { graphql } from "gatsby";

import HomepageHero from "../components/HomepageHero";
import WhatWeDo from "../components/WhatWeDo";
import Banner from "../components/Banner";
import MediaWithHeading from "../components/MediaWithHeading";
import ImageAndTextBox from "../components/ImageAndTextBox";
import News from "../components/News";
import HelmetInfo from "../components/HelmetInfo";

const Index = ({ data }) => {
  const {
    carousel,
    what_we_do_heading,
    what_we_do_links,
    about_us_video,
    about_us_video_poster_image,
    about_us_heading,
    about_us_button_text,
    about_us_button_link,
    banner_heading,
    banner_text,
    investors_image,
    investors_heading,
    investors_text,
    investors_button_text,
    investors_button_link,
    news_heading,
    news_button_text,
    news_button_link,
    team_image,
    team_heading,
    team_button_text,
    team_button_link,
  } = data.prismicHomepage.data;

  const articles = data.allPrismicNewsArticle.edges;
  return (
    <>
      <HomepageHero items={carousel} />
      <WhatWeDo heading={what_we_do_heading} items={what_we_do_links} />
      <Banner heading={banner_heading} text={banner_text} />
      <MediaWithHeading
        video={about_us_video}
        posterImage={about_us_video_poster_image}
        heading={about_us_heading}
        buttonText={about_us_button_text}
        buttonLink={about_us_button_link}
      />
      <ImageAndTextBox
        image={investors_image}
        heading={investors_heading}
        text={investors_text}
        buttonText={investors_button_text}
        buttonLink={investors_button_link}
        maxHeight={371}
        darkBackground={true}
      />
      <News
        articles={articles}
        heading={news_heading}
        buttonText={news_button_text}
        buttonLink={news_button_link}
      />
      <MediaWithHeading
        image={team_image}
        heading={team_heading}
        smallHeading={true}
        buttonText={team_button_text}
        buttonLink={team_button_link}
      />
    </>
  );
};

export default Index;

export const query = graphql`
  query IndexQuery {
    prismicHomepage {
      data {
        carousel {
          heading
          subheading
          image {
            alt
            copyright
            url
          }
          button_text
          button_link {
            link_type
            url
          }
        }
        what_we_do_heading
        what_we_do_links {
          image {
            alt
            copyright
            url
          }
          link {
            link_type
            uid
            type
          }
          title
        }
        about_us_video {
          link_type
          url
        }
        about_us_video_poster_image {
          alt
          copyright
          url
        }
        about_us_heading
        about_us_button_text
        about_us_button_link {
          link_type
          uid
          type
        }
        banner_heading
        banner_text
        investors_heading
        investors_text {
          text
          html
          richText
        }
        investors_button_text
        investors_button_link {
          link_type
          url
        }
        investors_image {
          alt
          copyright
          url
        }
        news_heading
        news_button_text
        news_button_link {
          link_type
          uid
          type
        }
        team_image {
          alt
          copyright
          url
        }
        team_heading
        team_button_text
        team_button_link {
          link_type
          uid
          type
        }
      }
    }
    allPrismicNewsArticle(
      sort: { order: DESC, fields: data___date }
      limit: 3
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
              richText
            }
            date
          }
        }
      }
    }
  }
`;
