import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";

import { linkResolver } from "../utils/linkResolver";

import Container from "../styles/Container";
import PrimaryButton from "./PrimaryButton";

const OuterContainer = styled.div`
  background-color: "${(p) => p.theme.white}";
  ${(p) => p.darkBackground && `background-color: ${p.theme.greyishBlue}`}
`;

const Wrapper = styled.div`
  padding-top: 37px;
  padding-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    align-items: center;
    ${(p) => p.maxHeight && `max-height: ${p.maxHeight}px;`}
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Left = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 50%;
    margin-right: 30px;
    display: flex;
    ${(p) => p.maxHeight && `max-height: ${p.maxHeight - 100}px;`}
  }
`;

const Right = styled.div`
  color: white;
  ${(p) => p.lightBackground && `color: ${p.theme.textColor};`}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 50%;
    margin-left: 30px;
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 32px;
  max-height: 100%;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 0px;
    object-fit: contain !important;
  }
`;

const Heading = styled.h1`
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 27px;
  text-align: center;
  color: white;
  ${(p) => p.lightBackground && `color: ${p.theme.greyishBlue};`}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    text-align: left;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 20px;
  }
`;

const Text = styled.div`
  p {
    font-family: "effra", sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 30px;
    ${(p) => !p.buttonText && "margin-bottom: 0px;"}
  }
  a {
    text-decoration: underline;
  }
`;

const ImageAndTextBox = ({
  image,
  heading,
  text,
  buttonText,
  buttonLink,
  lightBackground,
  darkBackground,
  maxHeight,
}) => {
  const buttonLinkUrl = buttonLink.url || linkResolver(buttonLink);
  return (
    (image || heading || text) && (
      <OuterContainer
        lightBackground={lightBackground}
        darkBackground={darkBackground}
      >
        <Container>
          <Wrapper maxHeight={maxHeight}>
            <Left maxHeight={maxHeight}>
              <Image src={image.url} alt={image.alt} />
            </Left>
            <Right lightBackground={lightBackground}>
              {heading && (
                <Heading lightBackground={lightBackground}>{heading}</Heading>
              )}
              {text && (
                <Text buttonText={buttonText}>
                  {RichText.render(text.richText)}
                </Text>
              )}
              {buttonText && (
                <PrimaryButton
                  externalLink={buttonLinkUrl ? buttonLinkUrl : "/"}
                  text={buttonText}
                  inverted={lightBackground ? false : true}
                  alignLeft
                />
              )}
            </Right>
          </Wrapper>
        </Container>
      </OuterContainer>
    )
  );
};

export default ImageAndTextBox;
