import styled, { css } from "styled-components";

const Heading = styled.h1`
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 36px;
  font-weight: 900;
  text-align: center;
  ${(p) =>
    p.smallHeading &&
    css`
      @media (max-width: 460px) {
        font-size: 26px;
        line-height: 33px;
      }
    `}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 30px;
    font-size: 34px;
    /* ${(p) => p.small && "font-size: 34px;"} */
    line-height: 47px;
  }
`;

export default Heading;
