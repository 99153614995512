import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import get from "lodash.get";

import { linkResolver } from "../utils/linkResolver";
import getDate from "../utils/getDate";

import Container from "../styles/Container";
import Heading from "../styles/Heading";
import PrimaryButton from "./PrimaryButton";

const Wrapper = styled.div`
  margin-top: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 50px;
  }
`;

const NewsContainer = styled.div`
  margin-bottom: 35px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    /* margin-top: 43px; */
    margin-bottom: 30px;
    margin-top: 50px;
    display: flex;
  }
`;

const DateContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
`;

const Title = styled.div`
  font-family: "effra", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

const NewsBox = styled(Link)`
  display: block;
  margin-top: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 0px;
    width: calc((100% - 88px) / 3);
    :not(:last-child) {
      margin-right: 44px;
    }
  }
  :hover {
    ${Title} {
      text-decoration: underline;
    }
    ${DateContainer} {
      text-decoration: underline;
    }
  }
`;

const News = ({ heading, buttonLink, buttonText, articles }) => {
  const buttonLinkUrl = linkResolver(buttonLink);
  return (
    <Container>
      <Wrapper>
        {heading && <Heading small>{heading}</Heading>}
        {articles && articles[0].node && articles[0].node.data && (
          <NewsContainer>
            {articles.map(({ node: { uid, data } }, index) => {
              return (
                <NewsBox to={`/articles/${uid}`} key={index}>
                  {data.date && (
                    <DateContainer>{getDate(data.date)}</DateContainer>
                  )}
                  {data.title && (
                    <Title>{RichText.asText(data.title.richText)}</Title>
                  )}
                </NewsBox>
              );
            })}
          </NewsContainer>
        )}
        {buttonText && (
          <PrimaryButton
            link={buttonLinkUrl ? buttonLinkUrl : "/"}
            text={buttonText}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default News;
