import { Date } from "prismic-reactjs";

export default function getDate(date) {
  const JSDate = Date(date);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][JSDate.getUTCMonth()];
  return `${month} ${JSDate.getUTCDate()}, ${JSDate.getUTCFullYear()}`;
}
