exports.linkResolver = (doc) => {
  if (doc === undefined) return `/`;
  if (doc.type === "homepage") {
    return `/`;
  }
  if (doc.type === "news_article") {
    return `/articles/${doc.uid}`;
  }
  if (doc.type === "person") {
    return `/members/${doc.uid}`;
  }
  if (doc.type === "network") {
    return `/network/${doc.uid}`;
  }
  if (doc.type === "production") {
    return `/productions/${doc.uid}`;
  }
  if (doc.type === "distribution") {
    return `/distribution/${doc.uid}`;
  }
  if (doc.type === "series") {
    return `/series/${doc.uid}`;
  }
  if (doc.type === "movie") {
    return `/distribution/screen-media/international-titles/${doc.uid}`;
  }
  return `/${doc.uid}`;
};
