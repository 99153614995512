import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.lightBlue};
`;

const Wrapper = styled.div`
  padding-top: 37px;
  padding-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Heading = styled.h1`
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 27px;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 21px;
    line-height: 32px;
  }
`;

const Banner = ({ heading, text }) => {
  return (
    <OuterContainer>
      <Container>
        <Wrapper>
          {heading && <Heading>{heading}</Heading>}
          {text && <Paragraph>{text}</Paragraph>}
        </Wrapper>
      </Container>
    </OuterContainer>
  );
};

export default Banner;
