import React, { useRef } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { linkResolver } from "../utils/linkResolver";
import get from "lodash.get";

import Container from "../styles/Container";
import Heading from "../styles/Heading";
import PrimaryButton from "./PrimaryButton";

const Wrapper = styled.div`
  margin-top: 37px;
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
  }
`;

const VideoOuterContainer = styled.div`
  position: relative;
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
  }
`;
const VideoContainer = styled.div``;

const VideoElement = styled.video`
  width: 100%;
`;
const MediaWithHeading = ({
  image,
  video,
  posterImage,
  heading,
  buttonText,
  buttonLink,
  smallHeading,
}) => {
  const buttonLinkUrl = linkResolver(buttonLink);
  const videoElement = useRef(null);

  return (
    <Wrapper>
      {image && image.url && (
        <Container fullwidthMobile>
          {" "}
          <Image src={image.url} alt={image.alt} />
        </Container>
      )}
      {video && video.url ? (
        <Container fullwidthMobile>
          <VideoOuterContainer>
            <VideoContainer>
              <VideoElement
                preload="none"
                poster={posterImage.url}
                ref={videoElement}
                autoPlay
                muted
                controls
              >
                <source src={video.url} type="video/mp4" />
              </VideoElement>
            </VideoContainer>
          </VideoOuterContainer>
        </Container>
      ) : (
        posterImage &&
        posterImage.url && (
          <Container fullwidthMobile>
            <Image src={posterImage.url} alt={posterImage.alt} />
          </Container>
        )
      )}
      <Container>
        {heading && <Heading smallHeading={smallHeading}>{heading}</Heading>}
        {buttonText && (
          <PrimaryButton
            link={buttonLinkUrl ? buttonLinkUrl : "/"}
            text={buttonText}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default MediaWithHeading;
