import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import get from "lodash.get";
import { linkResolver } from "../utils/linkResolver";

import Container from "../styles/Container";
import Heading from "../styles/Heading";

const Wrapper = styled.div`
  padding-top: 37px;
  padding-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const BoxContainer = styled.div`
  margin-top: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 50px;
    display: flex;
  }
`;

const Box = styled(Link)`
  display: block;
  :not(:last-child) {
    margin-bottom: 37px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc((100% - 32px) / 2);
    :not(:last-child) {
      margin-bottom: 0px;
      margin-right: 32px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 30px;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const WhatWeDo = ({ heading, items }) => {
  return (
    <>
      <Container fullwidthMobile>
        <Wrapper>
          {heading && <Heading small>{heading}</Heading>}
          {items.length >= 1 && (
            <BoxContainer>
              {items.map(({ imageSharp, image, title, link }, index) => {
                const url = linkResolver(link);
                return (
                  <Box to={url ? url : "/"} key={index}>
                    <Image src={image.url} alt={image.alt} />
                    <Title>{title}</Title>
                  </Box>
                );
              })}
            </BoxContainer>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default WhatWeDo;
